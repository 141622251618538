<template>
  <div class="container"></div>
</template>

<script>
export default {
  name: "transferView"
}
</script>

<style scoped>

</style>